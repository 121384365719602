<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Settings">
      <!-- Optional: Add any header buttons if needed -->
    </page-header>

    <div class="flex-1 overflow-y-auto">
      <div class="p-6">
        <TabHeaders :tabs="settingsTabs" :selectedTab="currentSettingsTab" @tab-selected="onSettingsTabSelected"/>
        <div class="mt-4 bg-white p-5 rounded shadow-md">
          <!-- Settings Content -->
          <TabContent :currentTab="currentSettingsTab">

            <!-- Printer Settings Tab -->
            <template v-slot:printer-settings>
              <!-- Section: Choosing Printer -->
              <div class="bg-white p-5 rounded mb-4">
                <div class="flex items-center mb-4">
                  <h3 class="uppercase text-lg font-semibold text-primary">Choosing Printer</h3>
                  <hr class="border-primary mx-4 flex-grow">
                  <AppButton color="blue" label="Save" @click="saveSelectedPrinter" icon=true>
                    <template v-slot:icon>
                      <Icon iconType="save" class="mr-2"/>
                    </template>
                  </AppButton>
                </div>
                <p class="mb-2">Check your environment and select a printer:</p>

                <Dropdown v-model="selectedPrinter" :options="printers" optionLabel="name"
                          placeholder="<New Status>"
                          @change="populatePrinterDetail"/>

                <DataTable v-if="selectedPrinter.name" :value="selectedPrinterDetails" class="mt-6">
                  <Column v-for="col in columns" :key="col.field" :field="col.field" :header="col.header"></Column>
                </DataTable>

              </div>


              <!-- Section: Check DYMO Environment -->
              <div class="bg-white p-5 rounded mb-4">
                <div class="flex items-center justify-between">
                  <div class="flex-grow flex items-center">
                    <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">Environment Check</h3>
                    <hr class="flex-1 border-primary mx-4"/>
                  </div>

                  <AppButton color="green" label="Check" @click="initDymoTests" icon=true>
                    <template v-slot:icon>
                      <Icon iconType="checkbox" class="mr-2"/>
                    </template>
                  </AppButton>

                </div>
                <div v-if="isLoadingDymoCheck" class="text-center my-4">
                  <span>Loading...</span>
                </div>
                <div v-if="dymoCheckError" class="p-4 mb-4 text-red-700 bg-red-200 rounded">
                  {{ dymoCheckError }}
                </div>
                <div v-if="dymoCheckResults && !isLoadingDymoCheck" class="mt-4">
                  <ul>
                    <li v-for="(result, index) in dymoCheckResults" :key="index" class="mb-2">
                      {{ result.name }}:
                      <span :class="result.value ? 'text-green-500' : 'text-red-500'">
                    {{ result.value ? '✓' : '✗' }}
                  </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="bg-white p-5 rounded mb-4">
                <div class="flex items-center mb-4">
                  <h3 class="uppercase text-lg font-semibold text-primary">Printer Test</h3>
                  <hr class="border-primary mx-4 flex-grow">
                  <AppButton color="green" label="Print" @click="printTestLabel" icon="printer">
                    <template v-slot:icon>
                      <Icon iconType="print" class="mr-2"/>
                    </template>
                  </AppButton>
                </div>


                <div class="flex space-x-10">
                  <!-- Text Input Section -->
                  <div class="flex flex-col bg-white p-4 rounded-lg  border border-gray-200 w-2/6"
                       style="height: 250px;">
                    <label for="testLabelContent" class="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Test
                      Label Content (max 75 characters):</label>
                    <textarea id="testLabelContent" v-model="testLabelContent" maxlength="75"
                              class="w-full bge-input bge-input-spacing rounded resize-none"
                              rows="8" placeholder="Enter test label content here..."></textarea>
                  </div>

                  <!-- Label Preview Section -->
                  <div class="w-2/6" style="height: 250px;">
                    <div class="flex flex-col h-full p-4 rounded-lg"
                         :class="{'bg-orange-300': testLabelContent.trim(), 'bg-orange-200': !testLabelContent.trim()}">
                      <div class="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 text-left">Label Preview:
                      </div>
                      <div
                          class="flex-grow flex items-center justify-center p-2 border border-gray-200 rounded-lg bg-white">
                        <template v-if="testLabelContent.trim()">
                          <img :src="labelPreviewImage" alt="Label Preview" class="max-w-full h-auto rounded-md"/>
                        </template>
                        <template v-else>
                          <span class="text-sm text-gray-500">No preview yet.</span>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>

              </div>


            </template>

            <!-- Additional sections (General, Notifications, Account) -->
            <!-- These sections would follow a similar structure for consistency -->
            <!-- Example: -->
            <template v-slot:general>
              <div class="bg-white p-5 rounded mb-4">
                <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">General Settings</h3>
                <!-- General settings content here -->
              </div>
            </template>

            <template v-slot:notifications>
              <!-- Notifications settings structure -->
            </template>

            <template v-slot:account>
              <!-- Account settings structure -->
            </template>

          </TabContent>
        </div>
      </div>
    </div>


  </div>
</template>


<script>
import PageHeader from "@/components/PageHeader.vue";
import TabHeaders from "@/components/TabHeaders.vue";
import TabContent from "@/components/TabContent.vue";
import AppButton from '@/components/Button.vue';
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  mixins: [notificationMixin],
  components: {
    Icon,
    PageHeader,
    TabHeaders,
    TabContent,
    AppButton
  },
  data() {
    return {
      dymoCheckResults: null,
      dymoCheckError: null,
      isLoadingDymoCheck: false,
      currentSettingsTab: 'printer-settings',
      settingsTabs: [
        {id: 'printer-settings', label: 'Printer Settings'},
        {id: 'general', label: 'General'},
        {id: 'notifications', label: 'Notifications'},
        {id: 'account', label: 'Account'}
      ],
      columns: [
        {field: 'property', header: 'Property'},
        {field: 'value', header: 'Value'}
      ],
      printers: [],
      selectedPrinter: {},
      selectedPrinterDetails: {},
      isDefaultPrinter: true,
      testLabelContent: '',
      labelPreviewImage: '',
      error: null,
      labelPreviewSuccess: false,
    };
  },
  watch: {
    selectedPrinter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.populatePrinterDetail();
      }
    },
    testLabelContent(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateLabelPreview();
      }
    },
  },
  methods: {
    onSettingsTabSelected(selectedTab) {
      this.currentSettingsTab = selectedTab;
    },

    // async initDymoTests() {
    //   this.isLoadingDymoCheck = true;
    //
    //   await new Promise(resolve => setTimeout(resolve, 1000));
    //
    //   this.dymoCheckResults = null;
    //   this.dymoCheckError = null;
    //   try {
    //     // Assuming dymo.label.framework.init is synchronous or properly handled if asynchronous
    //     if (dymo.label.framework.init) {
    //       dymo.label.framework.init();
    //     }
    //     const result = dymo.label.framework.checkEnvironment();
    //     this.dymoCheckResults = [
    //       {name: "Browser Supported", value: result.isBrowserSupported},
    //       {name: "Framework Installed", value: result.isFrameworkInstalled},
    //       {name: "Web Service Present", value: result.isWebServicePresent, hideIfUndefined: true},
    //       {name: "Error Details", value: result.errorDetails, hideIfUndefined: true},
    //     ].filter(item => item.value !== undefined || !item.hideIfUndefined);
    //   } catch (error) {
    //     this.dymoCheckError = "Error checking DYMO environment: " + (error.message || error.toString());
    //   } finally {
    //     this.isLoadingDymoCheck = false;
    //   }
    // },
    // loadPrintersAsync() {
    //   dymo.label.framework.getPrintersAsync().then(printers => {
    //     if (printers.length == 0) {
    //       this.error = "No DYMO printers are installed. Install DYMO printers.";
    //       return;
    //     }
    //     this.printers = printers;
    //   }).thenCatch(e => {
    //     this.error = "Load Printers failed: " + e;
    //   });
    // },
    // populatePrinterDetail() {
    //   const printer = this.selectedPrinter;
    //   if (!printer) return;
    //
    //   // Convert details into an array of objects for DataTable
    //   let detailsArray = [
    //     {property: 'Printer Type', value: printer.printerType},
    //     {property: 'Printer Name', value: printer.name},
    //     {property: 'Model Name', value: printer.modelName},
    //     {property: 'Is Local', value: printer.isLocal.toString()},
    //     {property: 'Is Connected', value: printer.isConnected.toString()},
    //     {property: 'Is Twin Turbo', value: printer.isTwinTurbo.toString()},
    //   ];
    //
    //   dymo.label.framework.is550PrinterAsync(printer.name)
    //       .then(isRollStatusSupported => {
    //         const isRollSupported = isRollStatusSupported ? 'true' : 'false';
    //         detailsArray.push({property: 'Is Roll Status Supported', value: isRollSupported});
    //
    //         if (!isRollStatusSupported) {
    //           throw new Error('Roll status not supported');
    //         }
    //
    //         return dymo.label.framework.getConsumableInfoIn550PrinterAsync(printer.name);
    //       })
    //       .then(consumableInfo => {
    //         const consumableDetails = [
    //           {property: 'SKU', value: consumableInfo.sku},
    //           {property: 'Consumable Name', value: consumableInfo.name},
    //           {property: 'Labels Remaining', value: consumableInfo.labelsRemaining.toString()},
    //           {property: 'Roll Status', value: consumableInfo.rollStatus},
    //         ];
    //
    //         detailsArray = [...detailsArray, ...consumableDetails];
    //         this.selectedPrinterDetails = [...detailsArray]; // Update the array for reactivity
    //       })
    //       .thenCatch(e => {
    //         const errorDetails = [
    //           {property: 'SKU', value: 'n/a'},
    //           {property: 'Consumable Name', value: 'n/a'},
    //           {property: 'Labels Remaining', value: 'n/a'},
    //           {property: 'Roll Status', value: 'n/a'},
    //         ];
    //
    //         detailsArray = [...detailsArray, ...errorDetails];
    //         this.selectedPrinterDetails = [...detailsArray]; // Update the array for reactivity
    //       });
    // },

    async initDymoTests() {
      this.isLoadingDymoCheck = true;

      await new Promise(resolve => setTimeout(resolve, 1000)); // Simulating async operation

      try {
        if (dymo.label.framework.init) {
          dymo.label.framework.init(); // Initialize DYMO framework
        }
        const result = dymo.label.framework.checkEnvironment();
        this.dymoCheckResults = [
          {name: "Browser Supported", value: result.isBrowserSupported},
          {name: "Framework Installed", value: result.isFrameworkInstalled},
          {name: "Web Service Present", value: result.isWebServicePresent, hideIfUndefined: true},
          {name: "Error Details", value: result.errorDetails, hideIfUndefined: true}
        ].filter(item => item.value !== undefined || !item.hideIfUndefined);
      } catch (error) {
        this.dymoCheckError = "Error checking DYMO environment: " + error.toString();
      } finally {
        this.isLoadingDymoCheck = false;
      }

    },
    async loadPrintersAsync() {
      dymo.label.framework.getPrintersAsync().then(printers => {
        if (printers.length === 0) {
          this.error = "No DYMO printers are installed. Install DYMO printers.";
          return;
        }
        this.printers = printers;
        this.loadSavedPrinter();
      }).thenCatch(e => {
        this.error = "Load Printers failed: " + e;
      });
    },
    saveSelectedPrinter() {
      if (this.selectedPrinter) {
        this.$store.dispatch("storeLabelPrinter", this.selectedPrinter.name);
        this.notifySuccess("Printer '" + this.selectedPrinter.name + "' saved successfully.");
      } else {
        this.notifyWarning("No printer selected to save.");
      }
    },
    loadSavedPrinter() {
      const savedPrinterName = this.$store.state.labelPrinter;
      if (savedPrinterName) {
        debugger
        const foundPrinter = this.printers.find(p => p.name === savedPrinterName);
        if (foundPrinter) {
          this.selectedPrinter = foundPrinter;
          this.populatePrinterDetail();
        }
      }
    },
    populatePrinterDetail() {
      const printer = this.printers.find(p => p.name === this.selectedPrinter.name);

      if (!printer) return;

      let detailsArray = [
        {property: 'Printer Type', value: printer.printerType},
        {property: 'Printer Name', value: printer.name},
        {property: 'Model Name', value: printer.modelName},
        {property: 'Is Local', value: printer.isLocal.toString()},
        {property: 'Is Connected', value: printer.isConnected.toString()},
        {property: 'Is Twin Turbo', value: printer.isTwinTurbo.toString()},
      ];

      dymo.label.framework.is550PrinterAsync(printer.name)
          .then(isRollStatusSupported => {
            const isRollSupported = isRollStatusSupported ? 'true' : 'false';
            detailsArray.push({property: 'Is Roll Status Supported', value: isRollSupported});

            if (!isRollStatusSupported) {
              throw new Error('Roll status not supported');
            }

            return dymo.label.framework.getConsumableInfoIn550PrinterAsync(printer.name);
          })
          .then(consumableInfo => {
            const consumableDetails = [
              {property: 'SKU', value: consumableInfo.sku},
              {property: 'Consumable Name', value: consumableInfo.name},
              {property: 'Labels Remaining', value: consumableInfo.labelsRemaining.toString()},
              {property: 'Roll Status', value: consumableInfo.rollStatus},
            ];

            detailsArray = [...detailsArray, ...consumableDetails];
            this.selectedPrinterDetails = detailsArray;
          })
          .thenCatch(e => {
            const errorDetails = [
              {property: 'SKU', value: 'n/a'},
              {property: 'Consumable Name', value: 'n/a'},
              {property: 'Labels Remaining', value: 'n/a'},
              {property: 'Roll Status', value: 'n/a'},
            ];

            detailsArray = [...detailsArray, ...errorDetails];
            this.selectedPrinterDetails = detailsArray;
          });
    },


    async updateLabelPreview() {

      this.labelPreviewSuccess = false;

      const labelXml = this.getBaseLabelXml();
      const label = dymo.label.framework.openLabelXml(labelXml);

      label.setObjectText('TextObject1', this.testLabelContent);

      try {
        const pngData = label.render();
        this.labelPreviewImage = "data:image/png;base64," + pngData;
        this.labelPreviewSuccess = true;
      } catch (error) {
        console.error("Error updating label preview: ", error);
        this.notifyWarning("Failed to update label preview.");
      }
    },
    async printTestLabel() {

      if (!this.selectedPrinter || !this.selectedPrinter.name) {
        this.notifyWarning('Please select a printer.');
        return;
      }

      if (!this.testLabelContent.trim()) {
        this.notifyWarning('Please enter test label content.');
        return;
      }

      await this.updateLabelPreview();

      if (!this.labelPreviewSuccess) {
        return;
      }

      try {
        const labelXml = this.getBaseLabelXml();
        const label = dymo.label.framework.openLabelXml(labelXml);
        const formattedText = this.testLabelContent.split('\n').join('\n');
        label.setObjectText('TextObject1', formattedText);

        label.print(this.selectedPrinter.name); // Assuming 'name' is the correct identifier for your printers
        this.notifySuccess('Test label has been sent to printer.');
      } catch (error) {
        console.error("Printing Test Label Error: ", error);
        this.notifyWarning(`Error printing test label: ${error.message || error}`);
      }
    },
    getBaseLabelXml() {
      return `<?xml version="1.0" encoding="utf-8"?>
<DesktopLabel Version="1">
  <DYMOLabel Version="3">
    <Description>DYMO Label</Description>
    <Orientation>Landscape</Orientation>
    <LabelName>Address1982991</LabelName>
    <InitialLength>0</InitialLength>
    <BorderStyle>SolidLine</BorderStyle>
    <DYMORect>
      <DYMOPoint>
        <X>0.23</X>
        <Y>0.06</Y>
      </DYMOPoint>
      <Size>
        <Width>3.21</Width>
        <Height>0.9966666</Height>
      </Size>
    </DYMORect>
    <BorderColor>
      <SolidColorBrush>
        <Color A="1" R="0" G="0" B="0"></Color>
      </SolidColorBrush>
    </BorderColor>
    <BorderThickness>1</BorderThickness>
    <Show_Border>False</Show_Border>
    <DynamicLayoutManager>
      <RotationBehavior>ClearObjects</RotationBehavior>
      <LabelObjects>
        <TextObject>
          <Name>TextObject1</Name>
          <Brushes>
            <BackgroundBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BackgroundBrush>
            <BorderBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </BorderBrush>
            <StrokeBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </StrokeBrush>
            <FillBrush>
              <SolidColorBrush>
                <Color A="0" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FillBrush>
          </Brushes>
          <Rotation>Rotation0</Rotation>
          <OutlineThickness>1</OutlineThickness>
          <IsOutlined>False</IsOutlined>
          <BorderStyle>SolidLine</BorderStyle>
          <Margin>
            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
          </Margin>
          <HorizontalAlignment>Center</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <FitMode>AlwaysFit</FitMode>
          <IsVertical>False</IsVertical>
          <FormattedText>
            <FitMode>AlwaysFit</FitMode>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <IsVertical>False</IsVertical>
            <LineTextSpan>
              <TextSpan>
                <Text>ABCD</Text>
                <FontInfo>
                  <FontName>Segoe UI</FontName>
                  <FontSize>26.9</FontSize>
                  <IsBold>False</IsBold>
                  <IsItalic>False</IsItalic>
                  <IsUnderline>False</IsUnderline>
                  <FontBrush>
                    <SolidColorBrush>
                      <Color A="1" R="0" G="0" B="0"></Color>
                    </SolidColorBrush>
                  </FontBrush>
                </FontInfo>
              </TextSpan>
            </LineTextSpan>
          </FormattedText>
          <ObjectLayout>
            <DYMOPoint>
              <X>1.0325</X>
              <Y>0.3091666</Y>
            </DYMOPoint>
            <Size>
              <Width>1.605</Width>
              <Height>0.4983333</Height>
            </Size>
          </ObjectLayout>
        </TextObject>
      </LabelObjects>
    </DynamicLayoutManager>
  </DYMOLabel>
  <LabelApplication>Blank</LabelApplication>
  <DataTable>
    <Columns></Columns>
    <Rows></Rows>
  </DataTable>
</DesktopLabel>`;
    },
  },
  computed: {},
  mounted() {
    this.initDymoTests();
    this.loadPrintersAsync();
  },

};
</script>
